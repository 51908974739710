
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@common/src/utils/common";
import dayjs from "dayjs";
import BtnRadio from "@/components/scope/btn-radio.vue";
import FilterBar from "@common/src/components/scope/filter-bar.vue";
import echarts from "echarts";
import t from "@common/src/i18n/t";
import appCode from "@common/src/utils/app-code";
const statement = namespace("statement");
const base = namespace("base");
const management = namespace("management");
@Component({
  components: { BtnRadio, FilterBar },
  filters: {}
})
export default class Statement extends Vue {
  @statement.Action queryTireSalesAnalysis;
  @statement.Action queryTireSalesMonthAnalysis;
  @base.Action getDictionaryList;
  @management.Action getStoreList;

  dayjs = dayjs;
  splitThousands = splitThousands;
  emptyToLine = emptyToLine;
  searchForm: any = {
    storeCode: "", // 门店名称
    area: "", // 所在地区
    stateName: "", // 省级名称
    cityName: "", // 市级名称
    districtName: "", // 县级名称
    statisticMethod: "", // 时间范围类型, 1-按日,2-按月
    beginDate: "", // 开始时间 格式: 按日为yyyyMMdd, 按月为yyyyMM
    endDate: "" // 结束时间 格式: 按日为yyyyMMdd, 按月为yyyyMM
  };
  contentList: any = [];
  pieChartRadio: any = "0";
  barChartRadio: any = "0";

  created() {
    if (localStorage.getItem("searchFormData")) {
      let data = JSON.parse(localStorage.getItem("searchFormData"));
      localStorage.removeItem("searchFormData");
      this.searchForm = data;
    } else {
      if (appCode === "speedbiz-operation") {
        this.searchStore("");
      }
      this.getCurrentTime();
    }
    this.init();
  }
  getCurrentTime() {
    // 获取当前时间
    let timestamp = new Date().getTime() - 24 * 60 * 60 * 1000;
    let currentTime = new Date(timestamp);
    let year = String(currentTime.getFullYear());
    let month = this.addZero(currentTime.getMonth() + 1);
    let day = this.addZero(currentTime.getDate());
    this.searchForm.statisticMethod = "1";
    this.searchForm.beginDate = year + month + "01";
    this.searchForm.endDate = year + month + day;
  }
  addZero(num) {
    return num < 10 ? "0" + num : String(num);
  }
  mounted() {}
  storeList: any = [];
  get searchList() {
    if (localStorage.getItem("searchListData")) {
      let data = JSON.parse(localStorage.getItem("searchListData"));
      localStorage.removeItem("searchListData");
      return data;
    }
    let storeRow = [
      {
        label: t("v210831.store-name"),
        type: "select-search",
        value: "",
        prop: "storeCode",
        selectList: this.storeList,
        searchFun: this.searchStore
      },
      {
        label: t("setting.region"),
        type: "area",
        value: "",
        labels: [],
        prop: "area",
        anyLevel: true,
        labelWidth: "85px"
      }
    ];
    let timeRow = [
      {
        label: t("v210831.time-frame"),
        type: "select-view",
        value: "",
        prop: "statisticMethod",
        // 时间范围区间必须是同一年
        isSameYear: true,
        selectList: [
          {
            value: "1",
            label: t("v210831.query-by-day"),
            itemValue: "",
            itemType: "daterange",
            itemProp: "dateValue",
            itemValueFormat: "yyyyMMdd"
          },
          {
            value: "2",
            label: t("v210831.query-by-month"),
            itemValue: "",
            itemType: "monthrange",
            itemProp: "dateValue",
            itemValueFormat: "yyyyMM"
          }
        ]
      }
    ];
    if (appCode === "speedbiz-operation") {
      return [...storeRow, ...timeRow];
    } else if (appCode === "speedbiz") {
      return timeRow;
    }
    return [];
  }
  searchStore(val = "") {
    this.getStoreList({
      pageNum: 1,
      pageSize: 10,
      storeName: val
    }).then(data => {
      this.storeList = data.data.list
        ? data.data.list.map(item => {
            item.label = item.storeName;
            item.value = item.storeCode;
            return item;
          })
        : [];
    });
  }
  init() {
    this.getChartData();
  }
  dataLastUpdateTime: any = "";
  getChartData() {
    /**
     * @refName
     *  piePayChart  饼图 实收金额
     *  pieOrderChart  饼图 订单金额
     *  pieNumberChart  饼图 台次
     *  barPayChart  柱状图 实收金额
     *  barOrderChart  柱状图 订单金额
     *  barNumberChart  柱状图 台次
     */

    this.queryTireSalesAnalysis(this.searchForm).then(data => {
      if (data.data.dataLastUpdateTime) {
        this.dataLastUpdateTime = data.data.dataLastUpdateTime;
      }
      let chartDatas = data.data;
      console.log(data.data, t("v210831.chart"));
      let piePayChartData = {
        data: [
          {
            name: t("v210831.jiatong-brand"),
            value: chartDatas.gitiActualAmount
          },
          {
            name: t("v210831.non-jiatong-brand"),
            value: chartDatas.otherActualAmount
          }
        ],
        total: chartDatas.actualAmount,
        text: t("v210831.total-paid-in-tire"),
        tip: t("v210831.paid-in-amount")
      };
      this.setChart(piePayChartData, "piePayChart", "pie");
      let pieOrderChartData = {
        data: [
          { name: t("v210831.jiatong-brand"), value: chartDatas.gitiAmount },
          {
            name: t("v210831.non-jiatong-brand"),
            value: chartDatas.otherAmount
          }
        ],
        total: chartDatas.orderAmount,
        text: t("v210831.total-amount-of"),
        tip: t("finance.order-amount")
      };
      this.setChart(pieOrderChartData, "pieOrderChart", "pie");
      let pieNumberChartData = {
        data: [
          { name: t("v210831.jiatong-brand"), value: chartDatas.gitiNum },
          { name: t("v210831.non-jiatong-brand"), value: chartDatas.otherNum }
        ],
        total: chartDatas.totalNum,
        text: t("v210831.total-tire-sales"),
        tip: t("setting.amount"),
        isNumber: true
      };
      this.setChart(pieNumberChartData, "pieNumberChart", "pie");
    });
    this.queryTireSalesMonthAnalysis(this.searchForm).then(data => {
      let chartDatas = data.data.list;
      chartDatas = chartDatas.map(v => {
        let index = Number(v.date.split("-")[1]);
        return { ...v, index };
      });
      let list = [];
      for (let index = 1; index <= 12; index++) {
        let item = chartDatas.find(v => v.index === index);
        if (item) {
          list.push(item);
        } else {
          let tip = chartDatas[0].date.split("-")[0] + "-" + this.addZero(index);
          list.push({ date: tip });
        }
      }
      let barPayChartData = {
        data1: list.map(v => v.gitiActualAmount),
        data2: list.map(v => v.otherActualAmount),
        tip: list,
        unit: t("v210801.element")
      };
      this.setChart(barPayChartData, "barPayChart", "bar");
      let barOrderChartData = {
        data1: list.map(v => v.gitiAmount),
        data2: list.map(v => v.otherAmount),
        tip: list,
        unit: t("v210801.element")
      };
      this.setChart(barOrderChartData, "barOrderChart", "bar");
      let barNumberChartData = {
        data1: list.map(v => v.gitiNum),
        data2: list.map(v => v.otherNum),
        tip: list,
        unit: t("other.tiao"),
        isNumber: true
      };
      this.setChart(barNumberChartData, "barNumberChart", "bar");
    });
  }
  setChart(data, refName, type) {
    let MyChart = echarts.init(this.$refs[refName]);
    let options = {};
    if (type === "pie") {
      // 饼图pie
      options = {
        color: ["#2278F5", "#00B478", "#FFC300"],
        tooltip: {
          trigger: "item",
          formatter: params => {
            return `${params.name}<br/>${data.tip}: ${
              data.isNumber ? params.value : this.$localMark.currencySymbol + splitThousands(params.value)
            }(${params.percent}%)`;
          }
        },
        legend: {
          bottom: "0%",
          left: "center",
          x: "center",
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 24
        },
        title: {
          show: true,
          text: data.text,
          subtext: splitThousands(data.total),
          top: "43%",
          left: "center",
          textStyle: {
            color: "rgba(0, 0, 0, 0.45)",
            fontWeight: 400,
            fontSize: 12
          },
          subtextStyle: {
            color: "rgba(0, 0, 0, 0.85)",
            fontWeight: 500,
            fontSize: 18
          }
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false
            },
            data: data.data
          }
        ]
      };
    } else if (type === "bar") {
      // 柱状图bar
      options = {
        color: ["#2278F5", "#00B478", "#FFC300"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter: params => {
            let str = `${data.tip[params[0].dataIndex].date}<br/>`;
            params.forEach(item => {
              str += `${item.marker}${item.seriesName}: ${
                Number(item.value)
                  ? data.isNumber
                    ? item.value
                    : this.$localMark.currencySymbol + splitThousands(item.value)
                  : "-"
              }<br/>`;
            });
            return str;
          }
        },
        legend: {
          data: [t("v210831.jiatong-brand"), t("v210831.non-jiatong-brand")],
          bottom: "0%",
          left: "center",
          x: "center",
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 24
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true
            },
            data: [
              t("v210831.january"),
              t("v210831.february"),
              t("v210831.march"),
              t("v210831.april"),
              t("v210831.may"),
              t("v210831.june"),
              t("v210831.july"),
              t("v210831.august"),
              t("v210831.september"),
              t("v210831.october"),
              t("v210831.november"),
              t("v210831.december")
            ]
          }
        ],
        yAxis: [
          {
            name: t("v210831.company:") + data.unit,
            axisTick: { show: false },
            type: "value"
          }
        ],
        series: [
          {
            name: t("v210831.jiatong-brand"),
            type: "bar",
            barGap: 0,
            data: data.data1
          },
          {
            name: t("v210831.non-jiatong-brand"),
            type: "bar",
            data: data.data2
          }
        ]
      };
    }
    MyChart.setOption(options);
    window.addEventListener("resize", () => {
      MyChart.resize();
    });
  }
  handlePieChange(value) {
    this.pieChartRadio = value;
  }
  handleBarChange(value) {
    this.barChartRadio = value;
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        if (item.prop === "area") {
          this.searchForm.stateName = item.labels[0] || "";
          this.searchForm.cityName = item.labels[1] || "";
          this.searchForm.districtName = item.labels[2] || "";
        } else {
          this.searchForm[item.prop] = item.value;
        }
        if (item.type === "select-view") {
          item.selectList.forEach(val => {
            if (val.value === item.value) {
              if (val.itemProp === "dateValue") {
                this.searchForm.beginDate = val.itemValue[0] || "";
                this.searchForm.endDate = val.itemValue[1] || "";
              } else {
                this.searchForm[val.itemProp] = val.itemValue;
              }
            }
          });
        }
      }
    });
    this.getChartData();
  }
}
